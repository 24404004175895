import axios from 'axios';

function getWhitelableList(payload) {
  return axios.get('white-label/list', {
    params: payload
  })
}

function addWhitelabel(data) {
  return axios.post('white-label/add',data)
}

function switchModuleStatus(id, isWL=null) {
  let wl_code = axios.defaults.headers.wl_code
  if(isWL){
    axios.defaults.headers.wl_code = isWL
  }
  const response = axios.put(`module/${id}`)
  axios.defaults.headers.wl_code = wl_code
  return response
}

function getWhitelabelDetail(id) {
  return axios.get(`white-label/${id}`)
}

function activeDeactiveMenu(id) {
  return axios.put(`white-label/active-deactive/${id}`)
}

function addNewMenu(data) {
  return axios.post("white-label/add-menu", data)
}

function updateMenu(id, data) {
  return axios.put(`white-label/update-menu/${id}`, data)
}

function getMenuDetail(id) {
  return axios.get(`white-label/get-menu-detail/${id}`)
}

function signInAsWLUser(id, isWL) {
  let wl_code = axios.defaults.headers.wl_code
  if(isWL){
    axios.defaults.headers.wl_code = isWL
  }
  const response = axios.post('admin/signin-as-admin', id)
  return response
}

function updateShowNewsletter(id) {
  return axios.put(`white-label/show-newsletter/${id}`)
}

export default {
    getWhitelableList,
    addWhitelabel,
    switchModuleStatus,
    getWhitelabelDetail,
    activeDeactiveMenu,
    getMenuDetail,
    addNewMenu,
    updateMenu,
    signInAsWLUser,
    updateShowNewsletter
}