<template>
  <b-card no-body class="custom_zindex">
    <b-card-header>
      <b-card-title>Customer VS Country</b-card-title>
      <div class="d-flex align-items-center">
        <h3>Total Users: {{ totalUsers }}</h3>
      </div>
    </b-card-header>
    <b-card-body>
      <!-- map -->
    <l-map
      :zoom.sync="zoom"
      :options="mapOptions"
      :center="center"
      :min-zoom="minZoom"
      :max-zoom="maxZoom"
      style="height: 500px; width: 100%"
    >
      <l-control-layers
        :position="layersPosition"
        :collapsed="false"
        :sort-layers="true"
      />
      <l-tile-layer
        v-for="tileProvider in tileProviders"
        :key="tileProvider.name"
        :name="tileProvider.name"
        :visible="tileProvider.visible"
        :url="tileProvider.url"
        :attribution="tileProvider.attribution"
        layer-type="base"
      />
      <l-control-zoom :position="zoomPosition" />
      <l-control-attribution
        :position="attributionPosition"
        :prefix="attributionPrefix"
      />
      <l-control-scale :imperial="imperial" />
      <l-marker
        v-for="marker in markers"
        :key="marker.id"
        :visible="marker.visible"
        :draggable="marker.draggable"
        :lat-lng.sync="marker.position"
        :icon="marker.icon"
      >
        <!-- <l-popup :content="marker.tooltip" /> -->
        <l-tooltip :content="marker.tooltip" />
      </l-marker>
    </l-map>
    </b-card-body>
  </b-card>
</template>

<script>
import BCardCode from '@/@core/components/b-card-code/BCardCode.vue'
import {
  BButton, BFormSelect, BRow, BCol, BFormGroup, BCard, BCardHeader, BCardBody, BCardTitle 
} from 'bootstrap-vue'
import {
  LMap,
  LTileLayer,
  LMarker,
  LPolyline,
  LLayerGroup,
  LTooltip,
  LPopup,
  LControlZoom,
  LControlAttribution,
  LControlScale,
  LControlLayers,
} from 'vue2-leaflet'
import { Icon } from 'leaflet'

// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

const tileProviders = [
  {
    name: 'OpenStreetMap',
    visible: true,
    attribution:
      '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  },
  {
    name: 'OpenTopoMap',
    visible: false,
    url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
    attribution:
      'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
  },
]

export default {
  name: 'Example',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPolyline,
    LLayerGroup,
    LTooltip,
    LPopup,
    LControlZoom,
    LControlAttribution,
    LControlScale,
    LControlLayers,
    BButton,
    BFormSelect,
    BRow,
    BCol,
    BFormGroup,
    BCardCode,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle
  },
  props: {
    mapData: {
      type: Array,
      // default: []
    },
    isLoaded: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // codeLayerGroup,
      center: [47.497913, 19.040236],
      opacity: 0.8,
      // token: 'your token if using mapbox',
      mapOptions: {
        zoomControl: false,
        attributionControl: false,
        zoomSnap: true,
      },
      zoom: 3,
      minZoom: 3,
      maxZoom: 5,
      zoomPosition: 'topleft',
      attributionPosition: 'bottomright',
      layersPosition: 'topright',
      attributionPrefix: 'Vue2Leaflet',
      imperial: false,
      Positions: ['topleft', 'topright', 'bottomleft', 'bottomright'],
      tileProviders,
      totalUsers: 0
    }
  },
  computed: {
    markers() {
      const markersMapped = this.mapData?.map((geoData, index) => {
        this.totalUsers += geoData.UsersCount;
        return {
          id: `m${index}`,
          position: { lat: geoData.latitude, lng: geoData.longitude },
          tooltip: `${geoData.country}: ${geoData.UsersCount}`,
          draggable: false,
          visible: true,
        }
      })
      return markersMapped
    }
  }
}
</script>

<style lang="scss" scoped>
.custom_zindex{
  position: relative;
  z-index: 9;
}
</style>
