<template>
  <b-card no-body>
    <b-overlay
      variant="white"
      :show="false"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <b-card-body class="d-flex justify-content-between align-items-center hovered">
        <div class="truncate">
          <h2 class="mb-25 font-weight-bolder">
            {{ statistic }}
          </h2>
          <span>{{ statisticTitle }}</span>
        </div>
        <div v-b-tooltip.hover.focus.v-primary="hoverMessage">
          <b-avatar
            :variant="`light-${color}`"
            size="25"
          >
            <feather-icon
              size="21"
              :icon="icon"
            />
          </b-avatar>
        </div>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BAvatar, BOverlay, VBTooltip } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardBody,
    BAvatar,
    BOverlay,
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    isLoaded: {
      type: Boolean,
      default: false
    },
    hoverMessage: {
      type: String,
      default: '--'
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card {
  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}

.dark-layout {
  .b-overlay-wrap ::v-deep .b-overlay {
    // border: 10px solid red;
    .bg-white {
      background-color: $theme-dark-body-bg !important;
    }
  }
}

.hovered:hover {
  // border: 0.51px solid #d6d6d6;
  background: #dcf1f3;
  transition: 0.25s ease-in-out;
  box-sizing: content-box;
  cursor: pointer;
}
</style>