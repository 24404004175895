<template>
  <b-overlay
    variant="white"
    :show="!isLoaded"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <!-- <confirm-disable
      ref="disableModal"
      :msg="disableMsg"
      @on-hide="resetStatus"
      @on-submit="switchLiveMode"
    /> -->
    <section id="dashboard-analytics">
      <b-overlay
        variant="white"
        :show="false"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
      >
    
      <b-card no-body>
        <b-card-body>
          <b-row>
            <b-col v-if="wl_code == 1" cols="12" md="4" class="mb-md-1 mb-1">
              <label>Filter by WhiteLabel</label>
              <v-select
                v-model="wlCode"
                :options="whitelabelList"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                class="w-100"
                :reduce="val => val.value"
                placeholder="Select Whitelabel"
                :clearable="true"
              />
            </b-col>
            <b-col cols="12" :md="wl_code == 1 ? 4 : 6" class="mb-md-1 mb-1">
              <label>Filter by</label>
              <v-select
                v-model="filterBy"
                :options="definedRangeList"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                class="w-100"
                :reduce="val => val.value"
                placeholder="Select Filter by"
                :clearable="true"
                :disabled="isFilter"
              />
            </b-col>
            <b-col cols="12" :md="wl_code == 1 ? 4 : 6" class="mb-md-1 mb-1">
              <b-form-group label="Creation Date Range" label-for="mc-select-date">
                <date-picker
                  v-model="bookingRange"
                  style="width:100%"
                  type="date"
                  range
                  format="DD.MM.YYYY"
                  placeholder="Select Date Range"
                  :disabled-date="disabledRange"
                  :disabled="isRange"
                />
                <!-- <b-form-datepicker
                  v-model="bookingRange"
                  range
                  locale="en"
                  format="DD.MM.YYYY"
                  placeholder="Select Date Range"
                  :disabled-date="disabledRange"
                  :disabled="isRange"
                ></b-form-datepicker> -->
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      </b-overlay>
      <b-row class="match-height">
        <b-col lg="3" md="12">
          <h3>Bookings</h3>
          <b-row>
            <b-col md="12">
              <statistic-card-horizontal
              :is-loaded="isLoaded"
              icon="InfoIcon"
              color="success"
              hover-message="Total bookings with Confirmed, Pending, Failed, Cancelled status."
              :statistic="staticsData && staticsData.bookings ? staticsData.bookings.Total: 0"
              statistic-title="Total Bookings"
              />
            </b-col>
            <b-col md="12">
                <statistic-card-horizontal
                :is-loaded="isLoaded"
                icon="InfoIcon"
                color="success"
                hover-message='Count for only "Confirmed" bookings'
                :statistic="staticsData && staticsData.bookings ? staticsData.bookings.Confirmed: 0"
                statistic-title="Confirmed Bookings"
                />
            </b-col>
            <b-col md="12">
              <statistic-card-horizontal
                :is-loaded="isLoaded"
                icon="InfoIcon"
                color="success"
                hover-message='Count for only "Pending" bookings'
                :statistic="staticsData && staticsData.bookings ? staticsData.bookings.Pending: 0"
                statistic-title="Pending Bookings"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="9" md="12">
          <b-row>
            <b-col md="12">
            <chartjs-bar-chart
              :is-loaded="isLoaded"
              :chart-data="staticsData.bookingGraphData"
              :modules="moduleNames" />
          </b-col>
          </b-row>
        </b-col>
      </b-row>
      <h3>Payments</h3>
      <b-row class="match-height">
        <b-col lg="6" md="12">
            <statistic-card-horizontal
            :is-loaded="isLoaded"
            icon="InfoIcon"
            color="success"
            hover-message='Count for all "Bank Transfer" bookings'
            :statistic="staticsData && staticsData.payment ? staticsData.payment.bank: 0"
            statistic-title="Bank Transfer Bookings"
            />
        </b-col>
          <b-col lg="6" md="12">
            <statistic-card-horizontal
            :is-loaded="isLoaded"
            icon="InfoIcon"
            color="success"
            hover-message='Count for all "Card" Payment(Simplepay)bookings'
            :statistic="staticsData && staticsData.payment ? staticsData.payment.card: 0"
            statistic-title="Card Payment Bookings"
            />
        </b-col>
      </b-row>
      <h3>Revenue</h3>
      <b-row class="match-height">
        <b-col lg="4" md="12">
          <statistic-card-horizontal
          :is-loaded="isLoaded"
          icon="InfoIcon"
          color="success"
          hover-message='Revenue from all "Confirmed" FULL payment(no installment) bookings, Card as well as Bank Transfer.'
          :statistic="`${kFormatter(staticsData && staticsData.revenue ? staticsData.revenue.full: 0)} ${currencyData}`"
          statistic-title="Received Full Payment"
          />
        </b-col>
        <b-col lg="4" md="12">
          <statistic-card-horizontal
          :is-loaded="isLoaded"
          icon="InfoIcon"
          color="success"
          hover-message='Revenue from paid "Installment" bookings, Card as well as Bank Transfer.'
          :statistic="`${kFormatter(staticsData && staticsData.revenue ? staticsData.revenue.installment: 0)} ${currencyData}`"
          statistic-title="Received Installment Payment"
          />
        </b-col>
        <b-col lg="4" md="12">
          <statistic-card-horizontal
          :is-loaded="isLoaded"
          icon="InfoIcon"
          color="success"
          hover-message="Outstanding balance, of what the customers owe for their bookings. Includes All pending payments."
          :statistic="`${kFormatter(staticsData && staticsData.revenue ? staticsData.revenue.upcoming: 0)} ${currencyData}`"
          statistic-title="Upcoming Payment"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" class="d-flex align-items-left justify-content-left mb-2 mb-md-0">
          <h3>Customers</h3>
        </b-col>
        <b-col v-if="wl_code == 1" cols="12" md="6" class="d-flex align-items-left justify-content-left mb-2 mb-md-0">
          <h3>API Counter</h3>
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col :class="wl_code == 1 ? 'col-6' : 'col-12'" >
          <leaflet-popup
            v-if="isLoaded"
            :is-loaded="isLoaded"
            :map-data="staticsData.countriesUsersCount" />
        </b-col>

        <b-col v-if="wl_code == 1" cols="6">
          <b-card no-body>
            <div class="m-2">
              <b-row>
                <b-col cols="12" md="6" class="mb-md-0 mb-2">
                  <label>Month</label>
                  <v-select 
                    v-model="month"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr' "
                    :options="MonthsFilter"
                    class="w-100"
                    :reduce="val => val.value"
                  />
                </b-col>
                <b-col cols="12" md="6" class="mb-md-0 mb-2">
                  <label>Year</label>
                  <v-select 
                    v-model="year"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr' "
                    :options="YearsFilter"
                    class="w-100"
                    :reduce="val => val.value"
                  />
                </b-col>
              </b-row>
              <!-- Table Container Card -->
              <b-card no-body class="mb-0 mt-3 counter-height">
                <b-table 
                  ref="refUserListTable"
                  class="position-relative"
                  :items="apiCounter"
                  responsive
                  :fields="tableColumns"
                  primary-key="domain"
                  show-empty
                  empty-text="No Matching records found"
                >
                  <template #cell(domain)="staticsData">
                    <div style="width: 150px">
                      {{ staticsData.item.domain }}
                    </div>
                  </template>
                </b-table>
              </b-card>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BRow,
  BFormGroup,
  BCol,
  BSpinner,
  BOverlay,
  BFormDatepicker,
  BFormCheckbox,
  BAlert,
  BTable
} from 'bootstrap-vue';
import vSelect from "vue-select";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import "vue2-datepicker/index.css";
import { kFormatter } from '@core/utils/filter';
import ChartjsBarChart from './ChartjsBarChart.vue';
import LeafletPopup from './LeafletPopup.vue';
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue';
import DashboardService from '../../services/dashboard/dashboard.service';
import whitelabelService from '@/services/whitelabel/whitelabel.service';
import { resConditionCheck, MonthsFilter, YearsFilter, MonthName, YearName} from "@/helpers/constant";
import errorResponseHandler from "@/services/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from 'axios'
import ConfirmDisable from "@/views/confirm-disable/ConfirmDisable.vue"
import ModuleService from '@/services/module/module.service'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BRow,
    BCol,
    BFormGroup,
    ChartjsBarChart,
    StatisticCardHorizontal,
    LeafletPopup,
    vSelect,
    DatePicker,
    BSpinner,
    BOverlay,
    BFormDatepicker,
    BFormCheckbox,
    ConfirmDisable,
    BAlert,
    BTable,
  },
  data() {
    return {
      data: '',
      graphFilter: {
        booking_start_date: '',
        booking_end_date: '',
        counter_year: YearName(new Date().getFullYear())
      },
      staticsData: {},
      bookingRange: [],
      wlCode: 1,
      filterBy: '',
      isLoaded: false,
      isRange: false,
      isFilter: false,
      whitelabelList: [{ label: 'Beontrips', value: 1 }],
      wl_code: axios.defaults.headers.wl_code,
      tableColumns: [
        { key: "domain" },
        { key: "flightCounter", label: "flight" },
        { key: "hotelCounter", label: "hotel" },
      ],
      apiCounter: [],
      MonthsFilter,
      YearsFilter,
      month: {label: MonthName(new Date().getMonth()+1), value: new Date().getMonth() + 1},
      year: {label: YearName(new Date().getFullYear()), value: new Date().getFullYear()},
      moduleNames: [],
      currencyData: ''
    }
  },
  async created() {
    if(this.wl_code == 1) {
      await this.getWhitelabelList();
    }
    this.bookingRange = [moment(new Date()).subtract(2, 'days').toDate(),moment(new Date()).subtract(1, 'days').toDate()];
    // await this.getModuleStatus()
    // await this.getDashboardData();
    await this.getModules();
  },
  mounted() {},
  computed: {
    definedRangeList() {
      var first = new Date().getDate() - new Date().getDay();
      var firstday = new Date(new Date().setDate(first)).toUTCString();
      this.currencyData = this.$store.state.auth.currencyData;
      return [ 
        { 
          label: 'Previous',
          value: { 
            booking_start_date: moment(new Date()).subtract(1, 'days').format("YYYY-MM-DD"),
            booking_end_date: moment(new Date()).subtract(1, 'days').format("YYYY-MM-DD")
          } 
        }, 
        { 
          label: 'Today',
          value: {
            booking_start_date: moment(new Date()).format("YYYY-MM-DD"),
            booking_end_date: moment(new Date()).format("YYYY-MM-DD")
          } 
        }, 
        { 
          label: 'Last 7 days',
          value: {
            booking_start_date: moment(new Date()).subtract(7, 'days').format("YYYY-MM-DD"),
            booking_end_date: moment(new Date()).format("YYYY-MM-DD")
          } 
        }, 
        { 
          label: 'Last Week',
          value: {
            booking_start_date: moment(new Date(firstday)).subtract(1, "weeks").format("YYYY-MM-DD"),
            booking_end_date: moment(new Date(firstday)).format("YYYY-MM-DD")
          } 
        }, 
        { 
          label: 'Current Month',
          value: {
            booking_start_date: moment().startOf('month').format("YYYY-MM-DD"),
            booking_end_date: moment().endOf('month').format("YYYY-MM-DD")
          } 
        }, 
        { 
          label: 'Last Month',
          value: {
            booking_start_date: moment().startOf('month').subtract(1, 'months').format("YYYY-MM-DD"),
            booking_end_date: moment(new Date()).endOf('month').subtract(1, 'months').format("YYYY-MM-DD")
          } 
        },
      ]
    }
  },
  watch: {
    bookingRange: {
      handler(newValue) {
        this.graphFilter.booking_start_date = newValue[0]
          ? moment(newValue[0]).format("YYYY-MM-DD")
          : "";
        this.graphFilter.booking_end_date = newValue[1]
          ? moment(newValue[1]).format("YYYY-MM-DD")
          : "";
        if (newValue[0] !== null){
          this.isRange = false
          this.isFilter = true
        } else {
          this.isRange = false
          this.isFilter = false
        }
        this.getDashboardData();
      }
    },
    filterBy: {
      handler(newValue) {
        if(newValue) {
          this.isRange = true
          this.isFilter = false
          this.graphFilter.booking_start_date = newValue.booking_start_date
            ? moment(newValue.booking_start_date).format("YYYY-MM-DD")
            : "";
          this.graphFilter.booking_end_date = newValue.booking_end_date
            ? moment(newValue.booking_end_date).format("YYYY-MM-DD")
            : "";
        } else {
          this.isRange = false
          this.isFilter = false
          this.graphFilter.booking_start_date = ''
          this.graphFilter.booking_end_date = ''
        }
        this.getDashboardData();
      }
    },
    wlCode: {
      handler(val) {
        if(val === null) {
          this.wlCode = 1
        }
        this.$store.dispatch("auth/setCurrency", val);
        this.getDashboardData();
      }
    },
    year: {
      handler(val) {
        if(!val) {
          this.year = new Date().getFullYear();
        }
        this.graphFilter.counter_year = this.year ? this.year : YearName(new Date().getFullYear());
        this.getDashboardData();
      }
    },
    month: {
      handler(val) {
        if(!val) {
          this.month = {label: MonthName(new Date().getMonth()+1), value: new Date().getMonth() + 1}
        }
        let m = val ? val.value ? val.value : val : this.month.value
        this.apiCounter = this.staticsData.apiCounter.filter( item => {
          let e = item.month == Number(m) ? item : ''
          return e  
        })
      }
    }
  },
  methods: {
    kFormatter,
    async getDashboardData() {
      this.isLoaded = false
      const WLCODE = (localStorage.getItem("signinWLAdmin") && localStorage.getItem("whitelabel_code")) ? localStorage.getItem("whitelabel_code") : (this.wl_code !== 1) ? this.wl_code : this.wlCode; 
      await DashboardService.getDashboardData(this.graphFilter, WLCODE).then(res => {
        if (resConditionCheck(res.status) && res.data) {
          this.staticsData = res.data.data;
          this.apiCounter = this.staticsData.apiCounter.filter( item => {
            item.domain = item.name ? item.name : item.domain
            let e = item.month == Number(this.month.value) ? item : ''
            return e  
          })
          this.isLoaded = true
        }
      })
      .catch(error => {
        const errorData = errorResponseHandler(error);
        if (error.response.status === 404) {
          this.isLoaded = true
          this.staticsData = {};
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger"
            }
          });
        }
      })
    },

    disabledRange(date) {
      return date > new Date();
    },
    async getWhitelabelList() {
      this.isLoaded = false; 
      const payload = { no_of_result: 0, page: 1, order: "ASC", order_by: 'name' };
      await whitelabelService.getWhitelableList(payload)
      .then(res=>{
        if (resConditionCheck(res.status) && res.data.data) {
          this.isLoaded = true
          this.whitelabelList = res.data.data.map(item => {
            // if(!item.isDeleted) {
              return {
                label: item.name,
                value: item.id
              }
            // }
          });
          this.whitelabelList.push({ label: 'Beontrips', value: 1 })
        }
      })
      .catch(error=>{
        const errorData = errorResponseHandler(error);
        this.$toast({
          component: ToastificationContent,
          props: {
          title: errorData,
          icon: "X-CircleIcon",
          variant: "danger"
          }
        });
      })
    },
    async getModules() {
      await axios.get('module/details')
      .then(res=>{
        if (resConditionCheck(res.status) && res.data.data) {
          let modules = res.data.data.filter(e=> e.isActive);
          this.moduleNames = (modules && modules.length) ? modules : [];
        }
      })
      .catch(error=>{
        const errorData = errorResponseHandler(error);
        this.$toast({
          component: ToastificationContent,
          props: {
          title: errorData,
          icon: "X-CircleIcon",
          variant: "danger"
          }
        });
      })
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card {
  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}

.dark-layout {
  .b-overlay-wrap ::v-deep .b-overlay {
    // border: 10px solid red;
    .bg-white {
      background-color: $theme-dark-body-bg !important;
    }
  }
}

.counter-height {
  max-height: 474px;
}
</style>
